import { Box } from '@mui/material';
import { NextPage } from 'next';
import React from 'react';

import { Link } from 'src/components/next/Link';
import { NextImage } from 'src/components/next/NextImage';
import { Seo } from 'src/components/next/Seo';

import { Layout } from '../components/layout/Layout';
import { LayoutColumn } from '../components/layout/LayoutColumn';

const title = 'Domein geregistreerd voor later gebruik';

const DomainRegisteredForFutureUse: NextPage = () => (
	<Layout>
		<Seo title={title} />
		<LayoutColumn>
			<h1>{title}</h1>
			<p>
				De domeinnaam die je invoerde is omgeleid naar deze pagina omdat dit domein is geregistreerd
				voor toekomstig gebruik.
				<br />
				Klik door naar onze <Link href="/">homepage</Link> of bekijk onze{' '}
				<Link href="/projecten" title="Projecten">
					projecten
				</Link>
				.
			</p>
			<Box component="div" sx={{ px: ['auto', '25%'] }}>
				<NextImage alt="Miauw!" src="/media/kitten.png" priority />
			</Box>
		</LayoutColumn>
	</Layout>
);

export default DomainRegisteredForFutureUse;
