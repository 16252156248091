
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/domein-geregistreerd-voor-later-gebruik",
      function () {
        return require("private-next-pages/domein-geregistreerd-voor-later-gebruik.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/domein-geregistreerd-voor-later-gebruik"])
      });
    }
  